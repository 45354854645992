import { userStore } from '@js/home-nal/user/userStore.js';

export default {

    data() {
        return {
            subscribeURL: nal.shopURL + '/membership',
        };
    },
    computed: {
        isPremium() {
            return this.planType === 'premium';
        },
        isPlatinum() {
            return this.planType === 'platinum';
        },
        isUpgraded() {
            //return this.isPremium || this.isPlatinum;
            return this.$store.getters['user/isUpgraded'];
        },
        isFree() {
            return userStore.loggedIn() && (this.planType === 'free');
        },
        planType() {
            return this.$store.getters['user/planType'];
        },
        isPaidMember() {
            return this.planType && this.planType !== 'free';
        },
    },
};