import { reactive } from 'vue';
import nalRequest from '../../core/nalRequest.js';
import { DEFAULT_CURRENCY } from '@js/core/currencies.js';

export const userStore = reactive({
    loading: false,
    user: window.nal.user,
    profile: null,
    positions: null,
    aspirations: null,
    // methods
    setUser(user) {
        this.user = user;
    },
    setProfile(profile) {
        this.profile = profile;
    },
    setPositions(positions) {
        this.positions = positions;
    },
    setAspirations(aspirations) {
        this.aspirations = aspirations;
    },
    async fetchProfile() {
        if (this.profile) {
            return;
        }
        let url = '/api/executive/profile';
        const response = await nalRequest.get(url);
        const data = response.data;

        if (!data && Sentry) {
            Sentry.withScope(scope => {
                scope.setExtra('response', response);

                Sentry.captureMessage('No data when fetching newly registered profile');
            });
        } else {
            this.setProfile(data);
            this.setPositions(data.positions);
            this.setAspirations(data.aspirations);
        }
    },
    async uploadCv(form) {
        const { data } = await form.put('/api/executive/profile');
        this.setProfile(data);
        this.setPositions(data.positions);
        this.setAspirations(data.aspirations);
        this.user.import_count++;
    },
    async saveCurrent(phoneForm, currentForm) {
        const [{ phone }, { data: profile, meta }] = await Promise.all([
            phoneForm.put('/api/executive/account/phone', {
                clear: false,
                scrollToFirstError: true,
            }),
            currentForm.post(`/api/executive/${this.user.id}/current-status`, {
                clear: false,
                scrollToFirstError: true,
            }),
        ]);

        this.profile.headline = meta.headline;
        this.profile.currentStatus = profile;
        this.user.details.accountPhone = phone;
    },
    async savePosition(form) {
        let exists = !!form.id;
        let method = exists ? 'put' : 'post';
        const position = await form[method](`/api/executive/${this.user.id}/positions/${form.id || ''}`, {
            clear: false,
            scrollToFirstError: true,
        });

        let index = _.findIndex(this.positions, ['id', position.id]);

        if (~index) {
            this.positions = [...this.positions.slice(0, index), position, ...this.positions.slice(index + 1)];
        } else {
            this.positions = [...this.positions, position];
        }
    },

    async saveAspirations(form) {
        const aspirations = await form.post(`/api/executive/${this.user.id}/aspirations`, {
            clear: false,
            scrollToFirstError: true,
        });
        this.setAspirations(aspirations);
    },

    async saveCurrency(currencyForm) {
        await currencyForm.put('/api/executive/account/currency', { clear: false });
        _.set(this.user, 'settings.currency', currencyForm.currency);
    },
    getPhoneForm() {
        return new Form({
            phone: this.user.details.accountPhone || '',
        });
    },
    getCurrencyForm() {
        return new Form({
            currency: this.currency(),
        });
    },
    getProfile() {
        return this.profile;
    },
    getCurrentForm() {
        return new Form({
            firstname: this.user.details.firstName,
            lastname: this.user.details.lastName,
            headline: this.profile.headline || '',
            status: !_.isNil(this.profile.currentStatus?.status.value) ? this.profile.currentStatus.status.value : 2,
            positionType: this.defaultPositionType(),
            nedClassificationId: this.defaultClassificationId(),
            careers: this.defaultCareerId() ? [this.defaultCareerId()] : [],
            industries: this.defaultIndustryId() ? [this.defaultIndustryId()] : [],
            locations: this.defaultLocationId() ? [this.defaultLocationId()] : [],
            aliases: [],
        });
    },
    getPositionForm(position) {
        return new Form(_.assignWith({}, {
            id: null,
            primary: true,
            positionType: this.defaultPositionType(),
            title: position?.title ? position?.title : '',
            companyName: '',
            career: this.defaultCareer() ? [this.defaultCareer()] : [],
            industry: this.defaultIndustry() ? [this.defaultIndustry()] : [],
            location: this.defaultLocation() ? this.defaultLocation() : {},
            teamSizeId: null,
            nedClassificationId: this.defaultClassificationId(),
            summary: '',
            startDate: null,
            endDate: 'present',
            achievements: [],
            skills: [],
            companyTypes: [],
        }, (no, def, key) => {
            return this.handleValue(position, def, key);
        }));
    },
    handleValue(position, def, key) {
        if (!position) {
            return def;
        }
        // All fields with `id` in the name need to fetch
        // the nested id from the object, except for teamSizeId
        if (key === 'primary') {
            return true;
        }
        if (key === 'companyName') {
            return position.company?.name || def;
        }
        if (key === 'nedClassificationId') {
            key = 'classification.id';
        }
        let result = _.get(position, key, def);
        return this.getResultValue(def, key, result);
    },
    getResultValue(def, key, result) {
        const isCategoryKey = key === 'career' || key === 'industry' || key === 'location';
        if (isCategoryKey) {
            // coercing to array for use in category-field component
            // (even though only a single selection can be made)
            return result ? [result] : def;
        }
        if (key === 'positionType' && result === null) {
            result = def;
        }
        this.handleFormattedResult(key, result);
        if (key === 'endDate' && result && !_.isInteger(result[0])) {
            result = '9999-12-31';
        }
        return result;
    },
    handleFormattedResult(key, result) {
        if (_.isArray(result)) {
            result = this.handleResultArray(key, result);
        } else if (_.isObject(result)) {
            result = _.get(result, 'id');
        }
    },
    handleResultArray(key, result) {
        return key === 'companyTypes' ? _.map(result, 'id') : _.clone(result);
    },
    getAspirationsFormWithType(positionType, value) {
        return new Form(_.assignWith({}, {
            summary: '',
            permanentPosition: {
                bonus: 0,
                base: {
                    amount: value || 0,
                    currency: DEFAULT_CURRENCY,
                },
            },
            interimPosition: {
                rate: {
                    amount: value || 0,
                    currency: DEFAULT_CURRENCY,
                },
            },
            nedPosition: {
                rate: {
                    amount: value || 0,
                    currency: DEFAULT_CURRENCY,
                },
                classifications: this.defaultClassificationId() ? [{ id: this.defaultClassificationId() }] : [],
            },
            companyTypes: [],
            industries: this.defaultIndustryId() ? [this.defaultIndustryId()] : [],
            careers: this.defaultLocationId() ? [this.defaultLocationId()] : [],
            locations: this.defaultCareerId() ? [this.defaultCareerId()] : [],
        }, (no, def, key) => {
            key = key.replace('Position', '');
            let result = _.get(this.aspirations, key) || def;
            if (_.isPlainObject(result)) {
                if (key !== positionType) {
                    return null;
                }
                result = _.clone(result);
                if (key === 'ned' && result.classifications) {
                    result.classifications = _.map(result.classifications, 'id');
                }
            }
            if (_.isArray(result)) {
                if (!result.length) {
                    result = def;
                }
                result = _.map(result, 'id');
            }
            return result;
        }));
    },
    // computed
    loggedIn() {
        return !!this.user;
    },
    finishedRegistration() {
        return this.user && typeof this.user.page === 'undefined';
    },
    defaultPositionType() {
        return _.get(this.profile, 'currentStatus.positionType') || 'permanent';
    },
    defaultClassification() {
        return _.get(this.profile, 'currentStatus.classification');
    },
    defaultClassificationId() {
        return _.get(this.defaultClassification(), 'null', 1);
    },
    defaultCareer() {
        return _.get(this.profile, 'currentStatus.careers[0]');
    },
    defaultCareerId() {
        return _.get(this.defaultCareer(), 'id', null);
    },
    defaultIndustry() {
        return _.get(this.profile, 'currentStatus.industries[0]');
    },
    defaultIndustryId() {
        return _.get(this.defaultIndustry(), 'id', null);
    },
    defaultLocation() {
        return _.get(this.profile, 'currentStatus.locations[0]');
    },
    defaultLocationId() {
        return _.get(this.defaultLocation(), 'id', null);
    },
    currency() {
        return _.get(this.user, 'settings.currency', DEFAULT_CURRENCY);
    },
    autoConnect() {
        return _.get(this.user, 'settings.autoConnect', false);
    },
});